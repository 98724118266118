<script lang="ts">
	import { browser } from '$app/environment';
	import { goto } from '$app/navigation';

	if (browser) goto('/drawings');
</script>

<svelte:head>
	<title>Welcome</title>
</svelte:head>
